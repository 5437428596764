import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Paper,
  InputBase,
  Divider,
  IconButton,
  Button,
  TextField,
} from '@mui/material';
import {
  Send as SendIcon,
  Edit as EditIcon,
  Reply as ReplyIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { useMsal } from '@azure/msal-react';

const CommentsDialog = ({ open, handleClose }) => {
  const [commentsData, setCommentsData] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [newAttachment, setNewAttachment] = useState(null);
  const [attachmentPreview, setAttachmentPreview] = useState(null);
  const [editingCommentIndex, setEditingCommentIndex] = useState(null);
  const [replyingToCommentIndex, setReplyingToCommentIndex] = useState(null);
  const [replyText, setReplyText] = useState('');
  const fileInputRef = useRef(null);

  // Get the current user's name from MSAL context
  const userName = useMsal().accounts[0].name;

  useEffect(() => {
    setCommentsData([
      {
        comment: 'there is some updates please review them ',
        user: { name: 'Batcha Mohamed' },
        replies: [],
      },
      {
        comment: 'can u make it today',
        user: { name: 'Saruhasan' },
        replies: [],
      },
    ]);
  }, []);

  const handleAddComment = () => {
    if (newComment.trim() || newAttachment) {
      const newCommentData = {
        comment: newComment.trim(),
        user: { name: userName },
        replies: [],
        attachment: newAttachment,
      };
      setCommentsData((prev) => [...prev, newCommentData]);
      setNewComment('');
      setNewAttachment(null);
      setAttachmentPreview(null);
    }
  };

  const handleEditComment = (index) => {
    if (commentsData[index].user.name !== userName) return;

    if (editingCommentIndex === index) {
      const updatedComments = [...commentsData];
      updatedComments[index].comment = newComment.trim();
      setCommentsData(updatedComments);
      setEditingCommentIndex(null);
      setNewComment('');
    } else {
      setNewComment(commentsData[index].comment);
      setEditingCommentIndex(index);
    }
  };

  const handleReplyToComment = (index) => {
    if (replyingToCommentIndex === index) {
      const updatedComments = [...commentsData];
      updatedComments[index].replies.push({
        text: replyText,
        user: { name: userName },
      });
      setCommentsData(updatedComments);
      setReplyingToCommentIndex(null);
      setReplyText('');
    } else {
      setReplyingToCommentIndex(index);
      setReplyText('');
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setNewAttachment(file);
    setAttachmentPreview(URL.createObjectURL(file));
  };

  const handleAddAttachmentClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleKeyPressComment = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddComment();
    }
  };

  const handleKeyPressReply = (index, event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleReplyToComment(index);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogContent>
        <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
          <Typography variant="h5" gutterBottom>
            Comments
          </Typography>
          <List>
            {commentsData?.length ? (
              commentsData?.map((comment, index) => (
                <React.Fragment key={index}>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar>{comment?.user?.name.charAt(0)}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={comment?.user?.name || 'Unknown'}
                      secondary={
                        <>
                          {editingCommentIndex === index ? (
                            <TextField
                              fullWidth
                              value={newComment}
                              onChange={(e) => setNewComment(e.target.value)}
                              onKeyPress={handleKeyPressComment}
                              label="Edit comment"
                              variant="outlined"
                            />
                          ) : (
                            <span>{comment.comment}</span>
                          )}
                          <Box mt={1}>
                            {comment.user.name === userName && (
                              <IconButton
                                onClick={() => handleEditComment(index)}
                                size="small"
                                color={
                                  editingCommentIndex === index
                                    ? 'primary'
                                    : 'default'
                                }
                              >
                                {editingCommentIndex === index ? (
                                  <SaveIcon />
                                ) : (
                                  <EditIcon />
                                )}
                              </IconButton>
                            )}
                            <IconButton
                              onClick={() => handleReplyToComment(index)}
                              size="small"
                            >
                              <ReplyIcon />
                            </IconButton>
                          </Box>
                          {comment.attachment && (
                            <Box mt={2}>
                              <Typography variant="body2">
                                <AttachmentIcon /> {comment.attachment.name}
                              </Typography>
                            </Box>
                          )}
                          {comment.replies?.length > 0 && (
                            <Box mt={2}>
                              <Typography variant="body2" color="textSecondary">
                                Replies:
                              </Typography>
                              {comment.replies.map((reply, replyIndex) => (
                                <Box key={replyIndex} ml={3} mt={1}>
                                  <Typography variant="body2">
                                    {reply.user?.name || 'Unknown'}:{' '}
                                    {reply.text}
                                  </Typography>
                                </Box>
                              ))}
                            </Box>
                          )}
                          {replyingToCommentIndex === index && (
                            <Box mt={2}>
                              <TextField
                                fullWidth
                                label="Reply"
                                value={replyText}
                                onChange={(e) => setReplyText(e.target.value)}
                                onKeyPress={(e) =>
                                  handleKeyPressReply(index, e)
                                }
                              />
                              <Button
                                onClick={() => handleReplyToComment(index)}
                                color="primary"
                                style={{ marginTop: '10px' }}
                              >
                                {replyingToCommentIndex === index
                                  ? 'Send Reply'
                                  : 'Reply'}
                              </Button>
                            </Box>
                          )}
                        </>
                      }
                    />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))
            ) : (
              <>Add a comment on the task</>
            )}
          </List>
          <Box mt={2}>
            {attachmentPreview && (
              <Box mt={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1">Attachment Preview:</Typography>
                <img
                  src={attachmentPreview}
                  alt="Preview"
                  style={{ maxWidth: '10%' }}
                />
              </Box>
            )}
            <Paper
              component="form"
              sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Add a comment"
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                onKeyPress={handleKeyPressComment}
                inputProps={{ 'aria-label': 'add comment' }}
              />
              <IconButton
                type="button"
                sx={{ p: '10px' }}
                aria-label="attachment"
                onClick={handleAddAttachmentClick}
              >
                <AddPhotoAlternateIcon />
              </IconButton>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                color="primary"
                sx={{ p: '10px' }}
                onClick={handleAddComment}
                aria-label="send"
              >
                <SendIcon />
              </IconButton>
            </Paper>
            <input
              type="file"
              accept="image/*,application/pdf"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
          </Box>
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default CommentsDialog;
