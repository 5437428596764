import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createTask, deleteTask, getAllTasks, updateTask } from './api';
import { useParams } from 'react-router-dom';

//CREATE hook (post new task to api)
export function useCreateTask() {
  const queryClient = useQueryClient();
  const { projectId } = useParams();

  return useMutation({
    mutationFn: (task) => {
      return createTask({
        task: task.values,
        projectId,
        accessToken: task.accessToken,
      });
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ['tasks', projectId] }),
  });
}

//READ hook (get tasks from api)
export function useGetTasks({ accessToken }) {
  const { projectId } = useParams();
  return useQuery({
    queryKey: ['tasks', projectId],
    queryFn: () => getAllTasks({ projectId, accessToken }),
    enabled: accessToken !== null,
    // enabled: projectId ? true : false,
    // staleTime: 30000,
    // cacheTime: 60000,
    keepPreviousData: true, // Keep the previous data while refetching
  });
}

//UPDATE hook (put task in api)
export function useUpdateTask() {
  const queryClient = useQueryClient();
  // const { projectId } = useParams()
  return useMutation({
    mutationFn: (task) =>
      updateTask({ task: task.values, accessToken: task.accessToken }),
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['tasks'] }),
  });
}

//DELETE hook (delete task in api)
export function useDeleteTask() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteTask,
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['tasks'] }),
  });
}
