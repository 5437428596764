// import axios from "../../../../../../api/axios"
import { autodesk_axios as axios } from '../../../../../common/api/axios';

// export const createTimesheet = async ({ timesheet, taskId }) => {
export const createTimesheet = async ({ timesheet, taskId, accessToken }) => {
  // send timesheet along with taskId(from URI / state)
  return await axios.post(
    '/timesheet/create',
    { timesheet, taskId },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
};

export const getAllTimesheets = async ({ taskId, accessToken }) => {
  console.log('gettting time sheets ', taskId, accessToken);

  const { data } = await axios.get('/timesheet/all/' + taskId, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return data;
};
export const getOneTimesheet = async () => {};

export const updateTimesheet = async ({ timesheet, taskId, accessToken }) => {
  return await axios.put(
    '/timesheet/update/',
    { timesheet, taskId },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
};

export const deleteTimesheet = async ({ timesheetId, accessToken }) => {
  console.log({ timesheetId, accessToken }, 'to be deleted');
  return await axios.delete(`/timesheet/${timesheetId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
