import React, { useEffect, useState, useRef } from 'react';
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Paper,
  InputBase,
  Divider,
  IconButton,
} from '@mui/material';
import { Send as SendIcon } from '@mui/icons-material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useMsal } from '@azure/msal-react';
import { fetchComments, addComment } from '../../../service/firebaseService';

const CommentsDialog = ({ open, handleClose, parentId, parentType }) => {
  const user = useMsal().accounts[0].name;
  const [commentsData, setCommentsData] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [newAttachment, setNewAttachment] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const comments = await fetchComments(parentId, parentType); // Fetch comments with parentId and parentType
        setCommentsData(comments);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [parentId, parentType]);

  const handleAddComment = async () => {
    if (newComment.trim() || newAttachment) {
      await addComment(
        parentId,
        parentType,
        newComment.trim(),
        user,
        newAttachment,
      );
      setCommentsData((prevComments) => [
        ...prevComments,
        {
          commentText: newComment.trim(),
          attachment: newAttachment ? URL.createObjectURL(newAttachment) : null,
          userName: user,
        },
      ]);
      setNewComment('');
      setNewAttachment(null);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setNewAttachment(file);
    setImagePreview(URL.createObjectURL(file));
  };

  const handleAddAttachmentClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogContent>
        <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
          <Typography variant="h5" gutterBottom>
            Comments
          </Typography>
          <List>
            {commentsData.length ? (
              commentsData.map((comment, index) => (
                <React.Fragment key={index}>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar>{comment?.userName?.charAt(0)}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={comment?.userName || 'Unknown'}
                      secondary={
                        <>
                          {comment.commentText}
                          {comment.attachment && (
                            <div>
                              <img
                                src={comment.attachment}
                                alt="attachment"
                                style={{ maxWidth: '100%', marginTop: '10px' }}
                              />
                            </div>
                          )}
                        </>
                      }
                    />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))
            ) : (
              <Typography>No comments yet. Add one!</Typography>
            )}
          </List>
          <Box mt={2}>
            {imagePreview && (
              <Box
                mt={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  backgroundColor: '#f2f2f24d',
                  padding: '8px',
                  borderRadius: '5px',
                }}
              >
                <Typography variant="body1">Image Preview:</Typography>
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{ maxWidth: '10%' }}
                />
              </Box>
            )}
            <Paper
              component="form"
              sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Add a comment"
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                inputProps={{ 'aria-label': 'add comment' }}
              />
              <IconButton
                type="button"
                sx={{ p: '10px' }}
                aria-label="attachment"
                onClick={handleAddAttachmentClick}
              >
                <AddPhotoAlternateIcon />
              </IconButton>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                color="primary"
                sx={{ p: '10px' }}
                onClick={handleAddComment}
                aria-label="send"
              >
                <SendIcon />
              </IconButton>
            </Paper>
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
          </Box>
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default CommentsDialog;
