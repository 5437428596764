import React, { useContext, useEffect, useState } from 'react';
import { EditableTable } from '../../../../../common/components/table/EditableTable';
import RACI from './raci.interface';
import { createRaci, deleteRaci, getProjectRACI, updateRaci } from './api';
import { initialDialogState, RACIColumns } from './utils';
// import TimesheetDialog from './components/TimesheetDialog.tsx';
import UserDialog from './components/UsersDialog';
import { useParams } from 'react-router-dom';
import User from '../../../../UsersPage/interface/userInterface';
import { getUsers } from '../../../../UsersPage/api';
import Project from '../../../interfaces/projectInterface';
import { AuthContext } from '../../../../../common/contexts/authContext';
import axios from '../../../../../common/api/axios';
import { useMsal } from '@azure/msal-react';
import Viewer from './Viewer';
import { log } from 'console';
import CommentsDialog from '../PunchlistTab/components/CommentsDialog.tsx';

const initialData: RACI = {
  id: '',
  document: '',
  docLin: '',
  revNum: '',
  responsible: '',
};

interface RACIProps {
  getItems: (parentId: string) => Promise<RACI[]>;
}
export interface SharepointDcument {
  name: string;
  urn: string;
  id: string;
}
type DialogType = '' | 'acc' | 'cons' | 'inf';
const RACITab = ({ getItems }: RACIProps) => {
  const currentUser = 'currentUser';
  const [allUsers, setAllUsers] = useState<User[] | []>([]);
  const [dialogeType, setDialogType] = useState<DialogType>('');
  const [sharepointDocuments, setSharepointDocuments] = useState<
    SharepointDcument[] | []
  >([]);
  const [viewerUrn, setViewerUrn] = useState<string | undefined>('');
  const [racis, setRacis] = useState<RACI[] | []>([]);
  // const { instance, accounts } = useMsal();
  // const email = accounts[0]?.username || 'Me';

  // console.log('project users', project?.users);
  const { projectId } = useParams();

  const [dialogState, setDialogState] = React.useState(initialDialogState);
  const [selectedId, setSelectedId] = React.useState('');

  useEffect(() => {
    const getAllUsers = async () => {
      const users: User[] = (await axios.get('/user')).data;
      setAllUsers(users);
    };
    const getDocuments = async () => {
      // if (projectId) {
      //   const racisFromApi: RACI[] = await getProjectRACI(projectId);
      //   setRacis(racisFromApi);
      // }
      // const documentsData = (await axios.get('/sharepoint')).data;
      // console.log(documentsData);
      // const documents: SharepointDcument[] = documentsData.map((d: any) => ({
      //   name: d.name.split('-')[1],
      //   urn: d.urn,
      // }));
      // // console.log('documentsss ', documents);
      // setSharepointDocuments(documents);
    };
    getAllUsers();
    getDocuments();
  }, []);

  const handleButtonClick = (type: string, row: any) => {
    setSelectedId(row.original.id);
    console.log('row', type);

    if (type === 'mail') {
      setDialogState((prev) => ({ ...prev, mail: true }));
    } else if (type === 'acc') {
      setDialogType('acc');
      setDialogState((prev) => ({ ...prev, userDialog: true }));
    } else if (type === 'cons') {
      setDialogType('cons');

      setDialogState((prev) => ({ ...prev, userDialog: true }));
    } else if (type === 'inf') {
      setDialogType('inf');

      setDialogState((prev) => ({ ...prev, userDialog: true }));
    } else if (type === 'timesheet') {
      setDialogState((prev) => ({ ...prev, timesheet: true }));
    } else if (type === 'preview') {
      console.log('row', row.original);
      console.log('doc', sharepointDocuments);
      let prevUrn: string | undefined;
      // if (row.original.document === '2D') {
      //   prevUrn = sharepointDocuments.find((d) => d.name === '2d')?.urn;
      // } else {
      //   prevUrn = sharepointDocuments.find((d) => d.name === '3d')?.urn;
      // }
      prevUrn = sharepointDocuments.find((d) => d.name === '2d')?.urn;

      setViewerUrn(prevUrn);
      setDialogState((prev) => ({ ...prev, preview: true }));
    } else if (type === 'comments') {
      setDialogState((prev) => ({ ...prev, comments: true }));
    }
  };

  const handleDialogClose = () => {
    setDialogState(initialDialogState);
  };

  return (
    <div>
      <EditableTable<RACI>
        name="RACI"
        displayMode="row"
        columns={RACIColumns({
          allUsers,
          handleButtonClick: handleButtonClick,
          currentUser,
          sharepointDocuments,
        })}
        enableEditing={true}
        enableCreate={true}
        initialData={initialData}
        api={{
          getItems: getItems,
          createItem: createRaci,
          updateItem: updateRaci,
          deleteItem: deleteRaci,
        }}
        parentId={projectId!}
      />
      {selectedId && (
        <>
          <UserDialog
            open={dialogState.userDialog}
            handleClose={handleDialogClose}
            type={dialogeType}
            allUsers={allUsers}
            rowId={selectedId}
          />
          {/* <TimesheetDialog
            open={dialogState.timesheet}
            handleClose={handleDialogClose}
            task={selectedTask}
          /> */}
          <Viewer
            open={dialogState.preview}
            handleClose={handleDialogClose}
            urn={viewerUrn}
          />
        </>
      )}
      {selectedId ? (
        <div>
          <CommentsDialog
            open={dialogState.comments}
            handleClose={handleDialogClose}
          />
          {/* <TimesheetDialog open={dialogState.timesheet} handleClose={handleDialogClose} task={selectedTask} /> */}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default RACITab;
