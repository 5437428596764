import axios, { autodesk_axios } from '../../common/api/axios';
import User from './interface/userInterface';

export const getUsers = async (): Promise<User[]> => {
  console.log('hereee');
  //   const response = await autodesk_axios.get('/user');
  const response = await autodesk_axios.get('/dev/all');

  console.log('userss', response);
  return response.data;
};

export const createUser = async (user: User): Promise<void> => {
  await axios.post('/user', user);
};

export const updateUser = async (user: User): Promise<void> => {
  await axios.put(`/user/${user.id}`, user);
};

export const deleteUser = async (userId: string): Promise<void> => {
  await axios.delete(`/user/${userId}`);
};
