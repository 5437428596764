import { db } from './firebaseConfig';
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  onSnapshot,
  orderBy,
  serverTimestamp,
} from 'firebase/firestore';

// ** Add a comment with userName, optional attachment, and parentType **
export const addComment = async (
  parentId,
  parentType, // New parameter for parentType
  commentText,
  userName,
  attachment,
) => {
  console.log('uuuuuuuuuuuu', userName, attachment, parentType);
  try {
    const commentData = {
      parentId,
      parentType, // Storing the parentType
      commentText,
      userName,
      attachment,
      timestamp: serverTimestamp(), // To keep track of when the comment was added
    };

    await addDoc(collection(db, 'comments'), commentData);
  } catch (error) {
    console.error('Error adding comment:', error);
    throw error;
  }
};

// ** Fetch all comments for a given parentId and parentType (with userName) **
export const fetchComments = async (parentId, parentType) => {
  try {
    const q = query(
      collection(db, 'comments'),
      where('parentId', '==', parentId),
      where('parentType', '==', parentType), // Add the parentType filter
      orderBy('timestamp'),
    );
    const querySnapshot = await getDocs(q);

    const comments = [];
    querySnapshot.forEach((doc) => {
      comments.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    return comments; // Returns the comments array including userName and other fields
  } catch (error) {
    console.error('Error fetching comments:', error);
    throw error;
  }
};

// ** Real-time listener for comments with userName and parentType **
export const fetchCommentsRealTime = (parentId, parentType, callback) => {
  try {
    const q = query(
      collection(db, 'comments'),
      where('parentId', '==', parentId),
      where('parentType', '==', parentType), // Add the parentType filter
      orderBy('timestamp'),
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const comments = [];
      querySnapshot.forEach((doc) => {
        comments.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      callback(comments); // Passing comments to the callback for real-time updates
    });

    return unsubscribe; // Return unsubscribe function to stop listening to real-time updates
  } catch (error) {
    console.error('Error listening to comments real-time:', error);
    throw error;
  }
};

// // ** Optionally, delete a comment by its ID **
// export const deleteComment = async (commentId) => {
//   try {
//     const commentRef = doc(db, 'comments', commentId);
//     await deleteDoc(commentRef);
//   } catch (error) {
//     console.error('Error deleting comment:', error);
//     throw error;
//   }
// };
