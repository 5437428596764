import {
  Box,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import AutodeskViewer from '../SingleProjectPage/tabs/RACI/Viewer/AutodeskViewer';
import { useEffect, useState } from 'react';
import axios from '../../../common/api/axios';

interface ViewerProps {
  open: boolean;
  handleClose: (event: MouseEvent) => void;
  projectName: string;
}
// interface Model {
//   name: string;
//   _2D: string;
//   _3D: string;
// }

// const projects: Model[] = [
//   {
//     name: '4795',
//     _2D: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6Y2JfYnVja2V0LzQ3OTUtMTEwMC1NMDAuZHdn',
//     _3D: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6Y2JfYnVja2V0LzQ3OTUtMTEwMC1NMDAlMjBBc3NlbWJseS5kd2Y',
//   },
//   {
//     name: '4784',
//     _2D: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6Y2JfYnVja2V0LzQ3ODQtMTEwMC1NMDAuZHdn',
//     _3D: '',
//   },
//   //   {
//   //     name: '4795',
//   //     _2D: '',
//   //     _3D: '',
//   //   },
// ];
export interface SharepointDcument {
  name: string;
  urn: string;
  id: string;
}
export default function Viewer({
  open,
  handleClose,
  projectName,
}: ViewerProps) {
  // const [sharepointDocuments, setSharepointDocuments] = useState<
  //   SharepointDcument[] | []
  // >([]);
  const [urn, setUrn] = useState<string | undefined>('');
  // const [type, setType] = useState(0);
  useEffect(() => {
    // const currentModel: Model = projects.filter(
    //   (p) => p.name === projectName,
    // )[0];
    // const currentModel = projects[0]; // for now
    // setModel(currentModel);
    const getSharepointData = async () => {
      // const documentsData = (await axios.get('/sharepoint')).data;
      // console.log('docs', documentsData);
      // const documents: SharepointDcument[] = documentsData.map((d: any) => ({
      //   name: d.name.split('-')[1],
      //   urn: d.urn,
      // }));
      // // setSharepointDocuments(documents);
      // setUrn(documents.find((d) => d.name === '3d')?.urn);
    };

    getSharepointData();
  }, []);
  // const handleChange = (event: SelectChangeEvent<unknown>) => {
  //   setType(Number(event.target.value));
  //   console.log('event', event);
  //   if (event.target.value === 1) {
  //     setUrn(sharepointDocuments.find((d) => d.name === '2d')?.urn);
  //   } else if (event.target.value === 2) {
  //     setUrn(sharepointDocuments.find((d) => d.name === '3d')?.urn);
  //   }
  //   // console.log(urn);
  // };
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'lg'}>
      <DialogContent>
        {/* <Stack>
          <FormControl>
            <InputLabel id="model-selector-label">Select 2D/3D</InputLabel>
            <Select
              labelId="model-selector-label"
              id="model-selector"
              value={type}
              onChange={handleChange}
            >
              <MenuItem value={0}></MenuItem>
              <MenuItem value={1}>2D</MenuItem>
              <MenuItem value={2}>3D</MenuItem>
            </Select>
          </FormControl>
        </Stack>*/}
        {urn ? (
          <AutodeskViewer prepUrn={urn} />
        ) : (
          <Box>Please Select a model</Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
