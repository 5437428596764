import './App.css';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { RouterProvider } from 'react-router-dom';
import { router } from './routing/router';
import websiteTheme, { darkTheme } from './common/style/theme';
import AuthContextProvider from './common/contexts/authContext';
import {
  AuthContextMSAL,
  AuthContextMSALProvider,
} from './common/contexts/mslAuthContext';

declare global {
  interface Window {
    onModelSelectedTimeout?: any;
  }
}

function App() {
  return (
    <ThemeProvider theme={websiteTheme}>
      <CssBaseline />
      <AuthContextMSALProvider>
        <AuthContextProvider>
          <RouterProvider router={router} />
        </AuthContextProvider>
      </AuthContextMSALProvider>
    </ThemeProvider>
  );
}

export default App;
