import { autodesk_axios as axios } from '../../../../common/api/axios';
import { tokenHeader } from '../../../../common/api/tokenHeader';

// export const createTask = async ({ task, projectId }) => {
export const createTask = async ({ task, projectId, accessToken }) => {
  console.log('creating task', task);
  // send task along with projectId(from URI / state)
  return await axios.post(
    '/task/create',
    { task, projectId },
    tokenHeader(accessToken),
  );
};

export const getAllTasks = async ({ projectId, accessToken }) => {
  console.log('acceddssss', accessToken);
  const { data } = await axios.get(
    '/task/all/' + projectId,
    tokenHeader(accessToken),
  );
  //   console.log("tasks data", data);
  return data;
};

export const getOneTask = async () => {};

export const updateTask = async ({ task, accessToken }) => {
  return await axios.put('/task/update/', { task }, tokenHeader(accessToken));
};

export const deleteTask = async ({ taskId }) => {};

export const archiveTask = async ({ id, accessToken }) => {
  return await axios.put('/task/archive/' + id, id, tokenHeader(accessToken));
};
